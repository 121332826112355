import CreativeApi from '@/api/creative.api'

const state = {
  screeningPendingCreatives: [],
  reviewedCreatives: [],
  selectedCreative: {}
}

const getters = {   
  getFilesUrls(state) {
    return state.selectedCreative?.files?.map(f => f.temporaryFileUri) ?? []
  },

  getApprovalDocsUrls(state) {
    return state.selectedCreative?.approvalDocs?.map(f => f.temporaryFileUri) ?? []
  },
}

/** 
 * @type {import('vuex').ActionTree<typeof state>} 
 */
const actions = {
  async getScreeningPendingCreatives({ commit }) {
    const response = await CreativeApi.getScreeningPendingCreatives()
    commit('SET_SCREENING_PENDING_CREATIVES', response.data || [])
  },

  async getReviewedCreatives({ commit }, showRejected) {
    const response = await CreativeApi.getReviewedCreatives(showRejected)
    commit('SET_REVIEWED_CREATIVES', response.data || [])
  },

  async getCreativeById({ commit }, id) {
    const response = await CreativeApi.getCreativeById(id)
    commit('SET_SELECTED_CREATIVE', response.data)
  },

  async approveCreative({dispatch}, id ) {
    const response = await CreativeApi.reviewCreative(id, { 
      isApproved: true 
    })
    dispatch('getScreeningPendingCreatives')
    return response    
  }, 

  async rejectCreative({dispatch}, payload ) {    
    const response = await CreativeApi.reviewCreative(payload.id, {
      reason: payload.reason, 
      isApproved: false
    })
    dispatch('getScreeningPendingCreatives')
    return response    
  }, 

  async enableCreative(context, id) {
    const response = await CreativeApi.enableCreative(id)    
    return response    
  },
  
  async disableCreative(context, id) {
    const response = await CreativeApi.disableCreative(id)    
    return response    
  }, 
    
} 

/**
* @type {import('vuex').MutationTree<typeof state>}
*/
const mutations = {
  SET_SCREENING_PENDING_CREATIVES(state, data) {
    state.screeningPendingCreatives = data
  },
  SET_REVIEWED_CREATIVES(state, data) {
    state.reviewedCreatives = data
  },
  SET_SELECTED_CREATIVE(state, data) {
    state.selectedCreative = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
