<template>
  <div>
    <div
      v-if="isLoading"
      class="text-center p-5"
    >
      <CSpinner
        style="width:2rem;height:2rem;"
        color="info"
        grow
      />
    </div>

    <div
      v-else-if="biddingDetails.status === 'Ready'"
      class="accordion"
    >
      <dl
        class="row"
      >
        <dt class="col-sm-3 text-right">
          Base Offer URL (JSON)
        </dt>

        <dd class="col-sm-9">
          <a
            href
            class="ml-1"
            @click.prevent="
              copyUrl(biddingDetails.json)
            "
          ><CIcon
            name="cil-copy"
          /></a>
          {{ biddingDetails.json }}
        </dd>
      </dl>
    
      <h4>Code Snippets</h4>


      <!-- GET Method -->
      <CCard
        class="mb-3"
      >
        <CCardHeader
          tag="header"
          class="pl-2 clickable"
          role="tab"
          @click="codeSnippetAccordion['get'] = !codeSnippetAccordion['get']"
        >
          <h5
            class="ml-2 d-flex align-items-center mb-0"
          >
            <strong>Option 1 - GET</strong>
        
            <CIcon
              class="ml-auto mr-1"
              :name="codeSnippetAccordion['get'] ? 'cil-chevron-bottom' : 'cil-chevron-top'"
            />
          </h5>
        </CCardHeader>

        <CCollapse
          id="get-code"
          :show="codeSnippetAccordion['get']"
        >
          <div class="p-2 pl-3">
            Send a GET request to the following URL.

            <div class="mt-3">
              <h6>
                URL - For JSON response
              </h6>
              <CodeContainer :code-text="getMethodJSONUrl" />
            </div>

            <div class="mt-2">
              <h6>
                URL - For XML response
              </h6>
              <CodeContainer :code-text="getMethodXMLUrl" />
            </div>
          </div>
        </CCollapse>
      </CCard>

      <!-- POST Method - JSON -->
      <CCard
        class="mb-3"
      >
        <CCardHeader
          tag="header"
          class="pl-2 clickable"
          role="tab"
          @click="codeSnippetAccordion['json'] = !codeSnippetAccordion['json']"
        >
          <h5
            class="ml-2 d-flex align-items-center mb-0"
          >
            <strong>Option 1 - JSON - POST</strong>
        
            <CIcon
              class="ml-auto mr-1"
              :name="codeSnippetAccordion['json'] ? 'cil-chevron-bottom' : 'cil-chevron-top'"
            />
          </h5>
        </CCardHeader>
        <CCollapse
          :show="codeSnippetAccordion['json']"
          accordion="codeSnippet"
          role="tabpanel"
        >
          <div class="p-2 pl-3">
            <SellerBidCodeSnippet
              :url="biddingDetails.json"
              header="Content-Type: application/json"
              :parameters="jsonParameters"
            />
          </div>
        </CCollapse>
      </CCard>

      <!-- POST Method - XML -->
      <CCard
        no-body
      >
        <CCardHeader
          tag="header"
          class="pl-2 clickable"
          role="tab"
          @click="codeSnippetAccordion['xml'] = !codeSnippetAccordion['xml']"
        >
          <h5
            class="ml-2 d-flex align-items-center mb-0"
          >
            <strong>Option 1 - XML - POST</strong>
        
            <CIcon
              class="ml-auto mr-1"
              :name="codeSnippetAccordion['xml'] ? 'cil-chevron-bottom' : 'cil-chevron-top'"
            />
          </h5>
        </CCardHeader>
        <CCollapse
          :show="codeSnippetAccordion['xml']"
        >
          <div class="p-2 pl-3">
            <SellerBidCodeSnippet
              :url="biddingDetails.xml"
              header="Content-Type: application/xml"
              :parameters="xmlParameters"
            />
          </div>
        </CCollapse>
      </CCard>


      <h4>Parameters</h4>

      <SellerOfferBidParameters
        class="mb-5"
        :parameters="biddingDetails.parameters"
      />
    </div>

    <div v-else-if="biddingDetails.status === 'NotApproved'">
      Offer application not approved.
    </div>

    <div v-else-if="biddingDetails.status === 'NotAvailableYet'">
      Bidding URL not available yet, please get in touch with support.
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import SellerOfferBidParameters from './SellerOfferBidParameters.vue'
import SellerBidCodeSnippet from './SellerBidCodeSnippet.vue'
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import { showSuccessMessage } from '@/notification-utils'

function getParamValue(param) {
  switch(param.name) {
  case 'cid':
    return '14061571951'
  case 'addressState':
    return 'WA'
  case 'addressZip':
    return '90001'   
  }

  if (param.choices?.length) {
    return param.choices[0]
  }  
}

export default {
  name: 'SellerOfferBiddingDetails',

  components: {
    SellerOfferBidParameters,
    SellerBidCodeSnippet,
  },

  mixins: [copyToClipboardMixin],

  props: {
    offerRegistration: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      codeSnippetAccordion: {
        get: false,
        xml: false,
        json: false
      },

      isLoading: false
    }
  },

  computed: {
    ...mapState('sellerOfferRegistration', {
      offerRegistrationDetails: 'requestDetails'
    }),

    biddingDetails() {
      return this.offerRegistrationDetails.bidUrls
    },

    jsonParameters() {
      return JSON.stringify(this.biddingDetails?.parameters?.reduce((finalParamsJson, param) => {
        finalParamsJson[param.name] = getParamValue(param)

        return finalParamsJson
      }, {}) || {}, null, 2)
    },

    xmlParameters() {
      let xmlParameters = '<bid_request>\n'

      xmlParameters = this.biddingDetails?.parameters?.reduce((finalParamsFormatted, param) => {
        finalParamsFormatted += `\t<${param.name}>${getParamValue(param)}</${param.name}>\n`
        return finalParamsFormatted
      }, xmlParameters)

      xmlParameters += `</bid_request>`

      return xmlParameters
    },

    getMethodJSONUrl() {
      return `${this.biddingDetails.json}?${this.queryParameters}`
    },

    getMethodXMLUrl() {
      return `${this.biddingDetails.xml}?${this.queryParameters}`
    },

    queryParameters() {
      return this.biddingDetails?.parameters?.reduce((finalQueryParams, param, idx) => {
        finalQueryParams += `${param.name}=${getParamValue(param)}`
        
        if (idx !== this.biddingDetails?.parameters.length - 1) {
          finalQueryParams += '&'
        }

        return finalQueryParams
      }, '')
    }
  },

  async created () {
    try {
      this.isLoading = true

      await this.getOfferRegistrationBidUrls({ offerRegistrationId: this.offerRegistration.id, accountId: this.offerRegistration.accountId })
    } finally {
      this.isLoading = false
    }
  },

  methods: {
    ...mapActions('sellerOfferRegistration', [
      'getOfferRegistrationBidUrls'
    ]),

    copyUrl(url) {
      this.copyTextToClipboard(url)
      showSuccessMessage('Seller Offer URL copied to clipboard')
    }
  },
}
</script>

<style lang="scss" scoped>

</style>