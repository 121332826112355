<template>
  <div>
    <h2>
      Supply Demand
    </h2>    
    <hr>
    <CategorySupplyDemandFilters
      :category-id="selectedCategoryId"
      @update-category="updateCategory"
      @category-loaded="onCategoryLoaded"
    />
    <hr>

    <div>
      <div class="row">
        <div class="col-sm-12 col-lg-12 col-xl col-12 supply-side traffic-data pb-4">
          <h2 class="mb-4 d-flex align-items-end">
            Supply
            <CButton      
              variant="outline"
              size="sm"
              @click="loadAllSellers"
            >
              <CIcon                
                name="cil-sync"
              />
            </CButton>

            <span class="h5 ml-auto pl-5">
              Show
            </span>
            <CDropdown
              color="info"
              class="ml-2"
              :toggler-text="getFilterDropdownText(filterBySellers, 'Sellers')"
            >
              <CDropdownItem @click="showSellers('active')">
                Active Sellers
              </CDropdownItem>
              <CDropdownItem @click="showSellers('inactive')">
                Inactive Sellers
              </CDropdownItem>
              <CDropdownItem @click="showSellers('all')">
                All Sellers
              </CDropdownItem>
            </CDropdown>
          </h2>
          
          <CSpinner
            v-if="isLoadingSellerData"
          />
          
          <CategorySupplyDetails
            v-if="!isLoadingSellerData && !showAllSellers && !noSellersApprovedForSelectedCategory && sellerOffers && sellerOffers.length"
            :supply-side-data="supplySideData"
            :supply-side-traffic-sources="supplySideTrafficSources"
            :seller-offers="sellerOffers"
            :show-inactive-sellers="showInactiveSellers"
            :sellers-with-no-visibility-to-offers="sellersWithNoVisibilityToOffers"
            :sellers-not-participating-actively="sellersNotParticipatingActively"
          />

          <CategorySupplyAllSellers
            v-if="!isLoadingSellerData && categoryId && showAllSellers && !noSellersApprovedForSelectedCategory && sellerOffers && sellerOffers.length"
            :all-sellers-in-category="allSellersForSelectedCategory"
          />

          <h4
            v-if="!isLoadingSellerData && categoryId && noSellersApprovedForSelectedCategory"
            class="pl-3"
          >
            <i>No Sellers approved for the category</i>
          </h4>

          <h4
            v-if="categoryId && sellerOffers && !sellerOffers.length"
            class="pl-3"
          >
            <i>Category has no offers</i>
          </h4>
        </div>

        <div class="col-sm-12 col-lg-12 col-xl col-12 traffic-data pb-4">
          <h2 class="mb-4 d-flex align-items-end">
            Demand

            <span class="h5 ml-auto pl-5">
              Show
            </span>
            <CDropdown
              color="info"
              class="ml-2"
              :toggler-text="getFilterDropdownText(filterByBuyers, 'Buyers')"
            >
              <CDropdownItem @click="showBuyers('active')">
                Active Buyers
              </CDropdownItem>
              <CDropdownItem @click="showBuyers('inactive')">
                Inactive Buyers
              </CDropdownItem>
              <CDropdownItem @click="showBuyers('all')">
                All Buyers
              </CDropdownItem>
            </CDropdown>
          </h2>
          
          <CSpinner v-if="isLoadingBuyerData" />

          <CategoryDemandDetails
            v-if="!isLoadingBuyerData && !showAllBuyers && !noBuyersApprovedForSelectedCategory"
            :demand-side-data="demandSideData"
            :demand-side-traffic-sources="demandSideTrafficSources"
            :show-inactive-buyers="showInactiveBuyers"
            :buyers-not-participating-actively="buyersNotParticipatingActively"
          />

          <CategoryDemandAllBuyers
            v-if="showAllBuyers && !noBuyersApprovedForSelectedCategory"
            :all-buyers="allBuyers"
          />

          <h4
            v-if="noBuyersApprovedForSelectedCategory && !isLoadingBuyerData"
            class="pl-3"
          >
            <i>No Buyers approved for the category</i>
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import CategorySupplyDemandFilters from './CategorySupplyDemandFilters.vue'
import CategorySupplyDetails from './CategorySupplyDetails.vue'
import CategoryDemandDetails from './CategoryDemandDetails.vue'
import CategorySupplyAllSellers from './CategorySupplyAllSellers.vue'
import CategoryDemandAllBuyers from './CategoryDemandAllBuyers.vue'
import { showSuccessMessage } from '@/notification-utils'

const urlQueryParamMapping = {
  categoryId: 'categoryId',
}

export default {
  name: 'CategorySupplyDemand',

  title: 'Traffic Overview',

  components: {
    CategorySupplyDemandFilters,
    CategorySupplyDetails,
    CategoryDemandDetails,
    CategorySupplyAllSellers,
    CategoryDemandAllBuyers
  },

  mixins: [copyToClipboardMixin],

  url: urlQueryParamMapping,

  data() {
    return {
      isLoadingSellerData: false,
      isLoadingBuyerData: false,
      filterBySellers: 'active',
      filterByBuyers: 'active'
    }
  },

  computed: {
    ...mapState('category', {
      selectedCategory: 'selectedRow',
    }),

    ...mapState('categorySupplyDemand', [
      'selectedCategoryId'
    ]),

    ...mapGetters('categorySupplyDemand', [
      'supplySideData',
      'supplySideTrafficSources',
      'demandSideData',
      'demandSideTrafficSources',
      'sellerOffers',
      'unfilteredSellerOffers',
      'sellersNotParticipatingActively',
      'sellersWithNoVisibilityToOffers',
      'buyersNotParticipatingActively',
      'allSellersForSelectedCategory',      
      'allBuyers'
    ]),

    categoryId: {
      get() {
        return this.selectedCategoryId
      },

      async set(value) {
        if (this.selectedCategoryId !== value) {
          this.SET_CATEGORY(value)

          if (value) {
            await this.loadAllData()
          }
        }

      }
    },

    showInactiveSellers() {
      return this.filterBySellers === 'inactive'
    },

    showAllSellers() {
      return this.filterBySellers === 'all'
    },

    showInactiveBuyers() {
      return this.filterByBuyers === 'inactive'
    },

    showAllBuyers() {
      return this.filterByBuyers === 'all'
    },

    noSellersApprovedForSelectedCategory() {
      return !this.allSellersForSelectedCategory?.length
    },

    noBuyersApprovedForSelectedCategory() {
      return !this.allBuyers?.length
    }
  },

  async created () {
    await this.getTrafficSources()

    await this.loadAllSellers()    
  },

  methods: {
    ...mapActions('categorySupplyDemand', [
      'getAllSellerOffersWithRegistrationsForCategory',
      'getAllBuyerCampaignsForCategory',
      'getAllBuyersForCategory',
      'getAllSellers',
      'getSellersWithAccessToPrivateOffer'
    ]),

    ...mapMutations('categorySupplyDemand', [
      'SET_CATEGORY'
    ]),

    ...mapActions('trafficSource', {
      getTrafficSources: 'list'
    }),

    async loadAllSellers() {
      try {
        this.isLoadingSellerData = true      
        await this.getAllSellers()
      } catch (error) {
        //
      } finally {
        this.isLoadingSellerData = false
      }
    },

    async updateCategory() {
      await this.loadAllData()
    },

    async loadAllData() {
      try {
        
        this.isLoadingBuyerData = true        
        
        await this.getAllSellerOffersWithRegistrationsForCategory(this.categoryId)

        await this.getAllBuyersForCategory(this.categoryId)
        await this.getAllBuyerCampaignsForCategory(this.categoryId)
        this.isLoadingBuyerData = false

        this.loadSellersAccessDataToPrivateOffers()
      } catch (error) {
      //
      } finally {
        this.isLoadingBuyerData = false       
      }
    },

    loadSellersAccessDataToPrivateOffers() {
      this.unfilteredSellerOffers.forEach(so => {
        if (so.visibility.toLowerCase() === 'private') {
          this.getSellersWithAccessToPrivateOffer(so.id)
        }
      })
    },

    copyAccountIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('Account ID copied to clipboard')
    },

    onCategoryLoaded() {
      document.title = `Traffic Overview for ${this.selectedCategory.name}`
    },

    showSellers(status) {
      this.filterBySellers = status
    },

    showBuyers(status) {
      this.filterByBuyers = status
    },

    getFilterDropdownText(status, type) {
      switch (status) {
      case 'active':
        return `Active ${type}`

      case 'inactive':
        return `Inactive ${type}`
      
      default:
        return `All ${type}`
      }
    }
  },
}
</script>
<style scoped lang="scss">
.traffic-data {
  min-height: 300px;
}

.supply-side {
  border-right: 0px;
  border-bottom: 1px solid rgba(0, 0, 21, 0.2);
  margin-bottom: 1rem;
}

.seller-offer-registration-list {
  border: 1px solid rgba(0, 0, 21, 0.2);
  max-width: 300px;
}

@include media-breakpoint-up(xl) {
  .supply-side {
    margin-bottom: 0;
    border-right: 1px solid rgba(0, 0, 21, 0.2);
    border-bottom: 0px;
  }
}
</style>