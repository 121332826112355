var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',[_vm._v(" Missing seller offers "),_c('CIcon',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Displays a list of seller offers where seller is missing out'),expression:"'Displays a list of seller offers where seller is missing out'"}],attrs:{"name":"cil-ban","size":"custom"}})],1),_c('CCardBody',[(_vm.isLoading)?_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center",style:({ height: '10vh' })},[_c('CSpinner',{staticStyle:{"width":"4rem","height":"4rem"},attrs:{"color":"info","grow":""}})],1):_vm._e(),(!_vm.isLoading)?_c('CDataTable',{attrs:{"hover":"","column-filter":"","sorter":"","border":"","small":"","items":_vm.missingOffers,"fields":_vm.fields,"items-per-page":10,"pagination":{align: 'center'},"sorter-value":{ column: 'name', asc: false }},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.sellerOfferName; }))+" "),(item.isCategoryTestOnly)?_c('CBadge',{staticClass:"ml-1",attrs:{"color":"danger"}},[_vm._v(" Test ")]):_vm._e()],1)]}},{key:"countryCode",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.countryCode; }))+" ")])]}},{key:"languageCode",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.languageCode; }))+" ")])]}},{key:"reason",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getReasonText(item.reason))+" ")])]}},{key:"Actions",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.sellerOfferId)?_c('CButton',{staticClass:"mr-2 mt-2",attrs:{"color":"info","size":"sm","to":("/dashboard/seller-offers/" + (item.sellerOfferId) + "/edit"),"target":"_blank"}},[_vm._v(" View Seller Offer ")]):_vm._e()],1)]}}],null,false,2754310972)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }