var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[(_vm.isLoading)?_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center",style:({ height: '80vh' })},[_c('CSpinner',{staticClass:"custom-spinner",attrs:{"color":"info","grow":""}})],1):_c('div',[_c('CCardHeader',[_vm._t("header",[_c('CIcon',{staticClass:"mr-1",attrs:{"name":"cil-basket"}}),_vm._v(" Creatives ")])],2),_c('CCardBody',[_c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('CSwitch',{staticClass:"mr-2",attrs:{"variant":"3d","color":"primary","horizontal":"","size":"sm","checked":_vm.showRejected},on:{"update:checked":[function($event){_vm.showRejected=$event},_vm.loadCreatives]}}),_c('h5',{staticClass:"m-0"},[_vm._v(" Show Rejected ")])],1),_c('CDataTable',{attrs:{"small":"","border":"","sorter":"","hover":"","column-filter":"","items":_vm.formattedReviewedCreatives,"fields":_vm.fields,"items-per-page":10,"pagination":{align: 'center'}},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.id)+" "),_c('a',{staticClass:"ml-1",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();_vm.copyIdToClipboard(_vm.getSafe(function () { return item.id; }))}}},[_c('CIcon',{attrs:{"name":"cil-copy"}})],1)])]}},{key:"accountName",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('router-link',{attrs:{"to":{ name: 'SellerAccountDetails', params: { id: _vm.getSafe(function () { return item.account.id; })} },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.account.name; }))+" ")]),_c('a',{staticClass:"ml-1",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();_vm.copyAccountIdToClipboard(_vm.getSafe(function () { return item.account.id; }))}}},[_c('CIcon',{attrs:{"name":"cil-copy"}})],1)],1)]}},{key:"categoryName",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.category.name; }))+" ")])]}},{key:"trafficSourceName",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.trafficSource.name; }))+" ")])]}},{key:"countryId",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.country.id; }))+" ")])]}},{key:"language",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.language.id; }))+" ")])]}},{key:"stats",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.stats)?_c('div',{staticClass:"w-fixed-130"},[_c('div',[_vm._v("Total: "+_vm._s(_vm.getSafe(function () { return item.stats.total; })))]),_c('div',[_vm._v("Pending: "+_vm._s(_vm.getSafe(function () { return item.stats.pending; })))]),_c('div',[_vm._v("Approved: "+_vm._s(_vm.getSafe(function () { return item.stats.approved; })))]),_c('div',[_vm._v("Rejected: "+_vm._s(_vm.getSafe(function () { return item.stats.rejected; })))])]):_vm._e()])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',[_vm._v(_vm._s(_vm.getSafe(function () { return item.status; })))])])]}},{key:"enabled",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.getSafe(function () { return item.enabled ? 'Yes' : 'No'; } )))])])])]}},{key:"formattedCreationDate",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.formattedCreationDate)+" ")])]}},{key:"Actions",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{staticClass:"mt-1 ml-2",attrs:{"color":"info","size":"sm","to":("/dashboard/creatives/" + (item.id))}},[_vm._v(" View ")]),(item.enabled)?_c('CButton',{staticClass:"mt-1 ml-2",attrs:{"color":"warning","size":"sm"},on:{"click":function($event){return _vm.showDisableModal(item)}}},[_vm._v(" Disable ")]):_c('CButton',{staticClass:"mt-1 ml-2",attrs:{"color":"success","size":"sm"},on:{"click":function($event){return _vm.showEnableModal(item)}}},[_vm._v(" Enable ")])],1)]}}])})],1)],1)]),_c('CModal',{attrs:{"title":"Confirm enabling creative","show":_vm.warningEnableModal},on:{"update:show":function($event){_vm.warningEnableModal=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('ButtonLoading',{attrs:{"color":"success","loading":_vm.isLoading},on:{"click":_vm.enableCreativeFromModal}},[_vm._v(" Enable ")]),_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){_vm.warningEnableModal = false}}},[_vm._v(" Cancel ")])]},proxy:true}])},[_vm._v(" Are you sure you want to enable this creative? ")]),_c('CModal',{attrs:{"title":"Confirm disabling creative","show":_vm.warningDisableModal},on:{"update:show":function($event){_vm.warningDisableModal=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('ButtonLoading',{attrs:{"color":"warning","loading":_vm.isLoading},on:{"click":_vm.disableCreativeFromModal}},[_vm._v(" Disable ")]),_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){_vm.warningDisableModal = false}}},[_vm._v(" Cancel ")])]},proxy:true}])},[_vm._v(" Are you sure you want to disable this creative? ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }