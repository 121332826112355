<template>
  <CCard>
    <CCardHeader>
      Missing seller offers 
      <CIcon 
        v-c-tooltip="'Displays a list of seller offers where seller is missing out'" 
        name="cil-ban" 
        size="custom"
      />
    </CCardHeader>

    <CCardBody>
      <div
        v-if="isLoading"
        :style="{ height: '10vh' }"
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <CSpinner
          style="width:4rem;height:4rem;"
          color="info"
          grow
        />
      </div>

      <CDataTable
        v-if="!isLoading"
        hover
        column-filter
        sorter
        border
        small
        :items="missingOffers"
        :fields="fields"
        :items-per-page="10"
        :pagination="{align: 'center'}"
        :sorter-value="{ column: 'name', asc: false }"
      >
        <template #name="{item}">
          <td>
            {{
              getSafe(() => item.sellerOfferName)              
            }}
            <CBadge
              v-if="item.isCategoryTestOnly"
              color="danger"
              class="ml-1"
            >
              Test
            </CBadge>
          </td>
        </template>
        
        <template #countryCode="{item}">
          <td>
            {{
              getSafe(() => item.countryCode)              
            }}
          </td>
        </template> 

        <template #languageCode="{item}">
          <td>
            {{
              getSafe(() => item.languageCode)              
            }}
          </td>
        </template> 

        <template #reason="{item}">
          <td>
            {{
              getReasonText(item.reason)              
            }}
          </td>
        </template> 

        <template #Actions="{item}">
          <td>
            <CButton
              v-if="item.sellerOfferId"
              color="info"
              size="sm"
              class="mr-2 mt-2"
              :to="`/dashboard/seller-offers/${item.sellerOfferId}/edit`"
              target="_blank"
            >
              View Seller Offer
            </CButton>
          </td>
        </template>
      </CDataTable>      
    </CCardBody>
  </CCard>
</template>

<script>
import { showErrorMessage } from '@/notification-utils'
import SellerOfferApi from '@/api/sellerOffer.api'

export default {
  name: 'SellerAccountMissingOffers',
  
  props: {
    accountId: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      isActionLoading: false,
      isLoading: false,
      missingOffers: [],
      fields: [
        { key: 'name', label: 'Offer name', sorter: true },
        { key: 'categoryName', label: 'Category', sorter: true },
        { key: 'countryCode', label: 'Country', sorter: true },
        { key: 'languageCode', label: 'Language', sorter: true },
        { key: 'reason', label: 'Status', sorter: false },        
        { key: 'Actions', sorter: false, filter: false },
      ],      
    }
  },

  computed: {    
  },

  async created() {
    try{
      this.isLoading = true
      const response = await SellerOfferApi.getMissingSellerOffersForAccount(this.accountId) 

      this.missingOffers = response.data
    }
    catch(error) {
      showErrorMessage(error.response.data.title)
    }
    finally {
      this.isLoading = false
    }
  },

  methods: {
    getReasonText(reason) {
      switch (reason) {
        case 'CanApply':
          return 'Seller can apply to this offer'
        case 'NoAccessToPrivate':
          return 'Seller has no access to this private offer'
        case 'NoMatchingTrafficSource':
          return 'Seller\'s approved traffic sources and offer\'s allowed traffic sources do not overlap'
        case 'NoMatchingCategory':
          return 'Seller has no access to offer\'s category'
        case 'CategoryHasNoOffer':
          return 'This category has no offers'
        default:
          return ''
      }
    }
  }
}
</script>

<style>

</style>