<template>
  <CCard>
    <CCardHeader>
      Audience
    </CCardHeader>
    <CCardBody>
      <CForm
        novalidate
        @submit.prevent
      >
        <CRow class="form-group">
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            Location
          </CCol>
          <CCol sm="3">
            <CSwitch
              shape="pill"
              :checked.sync="audienceDetailsLocal.searchLocation"
              color="primary"
              @update:checked="handleSearchLocationChange"
            />
          </CCol>
        </CRow>

        <CRow 
          v-if="audienceDetailsLocal.searchLocation"
          class="form-group"
        >
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            No ICP
          </CCol>
          <CCol sm="3">
            <CSwitch
              shape="pill"
              :checked.sync="audienceDetailsLocal.noIcp"
              color="primary"
              @update:checked="handleNoIcpChange"
            />            
          </CCol>
        </CRow>

        <CRow 
          v-if="audienceDetailsLocal.searchLocation && !audienceDetailsLocal.noIcp"
          class="form-group"
        >
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            Location Type
          </CCol>
          <CCol sm="3">
            <CInputRadioGroup
              class="col-sm-9 mt-1"
              add-label-classes="text-right pr-4"
              add-input-classes="w-25"
              :options="['States', 'ZipCodes']"
              :custom="true"
              :inline="true"
              :checked.sync="audienceDetailsLocal.locationFilterType"
              @update:checked="formEdited = true"
            />
          </CCol>
        </CRow>        

        <CRow
          v-if="showLocationStates"
          class="form-group"
        >
          <CCol
            sm="3"
            class="text-right pr-4"
          />
          <CCol>
            <v-multiselect-listbox              
              id="input-search-locations"
              v-model="audienceDetailsLocal.selectedLocations"
              data-cy="multiselect-search-locations"
              class="msl-multi-select--full-width"
              :options="stateOptions"
              :reduce-display-property="(option) => option.label"
              :reduce-value-property="(option) => option.value"
              search-input-class=""
              search-options-placeholder="Search states"
              selected-options-placeholder="Search selected states"
              selected-no-options-text="No location selected"
              :show-select-all-buttons="true"
              no-options-text="No states"            
              @change="formEdited = true"
            />
          </CCol>          
        </CRow>

        <CRow 
          v-if="showLocationZipCodes"
          class="form-group"
        >
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            Upload zip codes list
          </CCol>
          <CCol sm="5">
            <CInputFile
              v-if="showLocationZipCodes"        
              id="upload-zip-codes"
              class="mt-1"
              custom
              add-label-classes="text-right pr-4"
              rows="1"
              invalid-feedback=""
              accept=".txt,.csv,.csv"
              data-cy="doc"
              :horizontal="true"
              :placeholder="zipCodeFileNamePlaceholder"
              @change="parseZipCodes"
            />
          </CCol>
        </CRow>       

        <div class="row">
          <div class="col-sm-3 pr-4" />
          <div class="col-sm-9">
            <ButtonLoading
              color="primary"
              :loading="isLoading"
              :disabled="!formEdited"
              horizontal
              size="sm"
              label=""
              @click="updateAudience"
            >
              Update
            </ButtonLoading>
          </div>
        </div>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { showSuccessMessage } from '@/notification-utils'

export default {
  name: 'Audience',  
  props: {
    campaignId: {
      type: String,
      default: ''
    },
    audienceDetails: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      formEdited: false,
      isLoading: false,       
      zipCodesFile: null,
      audienceDetailsLocal: {}
    }
  },
  computed: {

    ...mapState('country', ['usStates']),

    maxAges() {
      const arr = Array.from({ length: 48 }, (_, i) => (i + 18).toString())
      arr.push('65+')
      return arr
    },

    showLocationStates() {
      return this.audienceDetailsLocal.searchLocation && this.audienceDetailsLocal.locationFilterType === 'States'
    },

    showLocationZipCodes() {
      return this.audienceDetailsLocal.searchLocation && this.audienceDetailsLocal.locationFilterType === 'ZipCodes'
    },

    showPreviouslyUploadedZipCodes() {
      return !!this.audienceDetailsLocal.zipCodes && !!this.audienceDetailsLocal.zipCodes.length
    },

    zipCodeFileNamePlaceholder() {
      if (this.audienceDetailsLocal.zipCodesFileName) {
        return this.audienceDetailsLocal.zipCodesFileName
      } else {
        return "Upload file"
      }
    },
    
    stateOptions() {
      let stateOptions = []

      stateOptions = this.usStates.map(state => {
          return {
            value: state.abbreviation,
            label: state.name
          }
        })
      
      return stateOptions      
    }
  },
  watch: {
    audienceDetails: {
      immediate: true,
      handler(newValue) {
        this.audienceDetailsLocal = {
          ...newValue,
          searchLocation: Boolean(newValue.searchLocation)
        }
      }
    }
  },
  methods: {
    ...mapActions('campaign', ['updateCampaignDetails']),
    async updateAudience() {
      const campaignId = this.campaignId

      const updateObject = {
        audience: {
          ...this.audienceDetailsLocal
        }
      }
      try {
        this.isLoading = true
        await this.updateCampaignDetails({ id: campaignId, updateObject })
        showSuccessMessage('Updated audience settings')
      } catch (error) {
        console.error(error.message)
      } finally {
        this.isLoading = false
        this.formEdited = false
      }
    },

    handleSearchLocationChange() {
      this.formEdited = true

      if (this.audienceDetailsLocal.selectedLocations == null) {
        this.audienceDetailsLocal.selectedLocations = []
      }
    },

    handleNoIcpChange() {
      this.formEdited = true
      if (this.audienceDetailsLocal.noIcp) {
        this.audienceDetailsLocal.locationFilterType = 'States'
      }
    },

    parseZipCodes(files) {
      this.zipCodesFile = files[0]
      const reader = new FileReader()
      reader.onload = file => {      
        const zipCodes = file.target.result.split(/[\s\t,]+/)        
        this.$set(this.audienceDetailsLocal, 'zipCodes', zipCodes) 
        this.$set(this.audienceDetailsLocal, 'zipCodesFileName', this.zipCodesFile.name)         
      }
      reader.readAsText(this.zipCodesFile)
      this.formEdited = true 
    }
  }
}

</script>

<style lang="scss" scoped></style>
