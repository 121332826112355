import createHttpClient from './httpClient'

const customerBaseUrl = new URL('/api/v1/seller', process.env.VUE_APP_PUBLIC_SERVICE_BASE_URL).href

const baseUrl = new URL('/api/v1/seller-offers', process.env.VUE_APP_INTERNAL_SERVICE_BASE_URL).href
const httpClient = createHttpClient(baseUrl)

const REVIEW_END_POINT = 'applications'
const MANAGE_END_POINT = 'admin'

const SellerOfferApi = {
  //review
  getPendingRequests() {
    const url = `${REVIEW_END_POINT}/pending`
    return httpClient.get(url)
  },
  getAllRequests() {
    const url = `${REVIEW_END_POINT}`
    return httpClient.get(url)
  },
  
  getApprovedSellersBySellerOfferId(id) {
    const url = `${REVIEW_END_POINT}/?sellerOfferId=${id}&status=Approved`
    return httpClient.get(url)
  },

  async getApprovedSellerOfferRequestsForAccount(accountId) {
    const url = `${REVIEW_END_POINT}/account/${accountId}?status=Approved`
    return await httpClient.get(url)
  },

  async getMissingSellerOffersForAccount(accountId) {
    const url = `${REVIEW_END_POINT}/account/${accountId}/missing`
    return await httpClient.get(url)
  },

  getRequestDetailsById(id) {
    const url = `${REVIEW_END_POINT}/${id}`
    return httpClient.get(url)
  },
  removeUser(id) {
    const url = `${REVIEW_END_POINT}/${id}/remove`
    return httpClient.post(url, {})
  },
  approveSellerOfferRequest(payload) {
    const url = `${REVIEW_END_POINT}/${payload.id}/approve`
    return httpClient.post(url, payload)
  },
  rejectSellerOfferRequest(payload) {
    const url = `${REVIEW_END_POINT}/${payload.id}/reject`
    return httpClient.post(url, payload)
  },

  updateSellerOfferRequest(payload) {
    const url = `${REVIEW_END_POINT}/${payload.id}`
    return httpClient.patch(url, payload)
  },

  async getSellerOfferRequestBidUrls(accountId, requestId) {
    const url = `${customerBaseUrl}/${accountId}/offers/${requestId}/urls`
    return httpClient.get(url)
  },

  pauseUserInOffer(id, reason) {
    const url = `${REVIEW_END_POINT}/${id}/pause`
    return httpClient.post(url, {
      reason
    })
  },

  resumeUserInOffer(id) {
    const url = `${REVIEW_END_POINT}/${id}/resume`
    return httpClient.post(url)
  },

  //manage
  getAllSellerOffers(categoryId, includeRegistrations) {
    let url = `${MANAGE_END_POINT}?includeRegistrations=${!!includeRegistrations}`

    if (categoryId) {
      url += `&categoryId=${categoryId}`
    }
    return httpClient.get(url)
  },
  getSellerOfferDetailsById(id) {
    const url = `${MANAGE_END_POINT}/${id}`
    return httpClient.get(url)
  },
  createSellerOffer(payload) {
    const url = `${MANAGE_END_POINT}`
    return httpClient.post(url, payload)
  },
  upateSellerOffer(id, payload) {
    const url = `${MANAGE_END_POINT}/${id}`
    return httpClient.patch(url, payload)
  },
  changeSellerOfferStatus(id, status) {
    const url = `${MANAGE_END_POINT}/${id}/status`
    return httpClient.patch(url, { status: status })
  },

  changeSellerOfferVisibility(id, visibility) {
    const url = `${MANAGE_END_POINT}/${id}/visibility`
    return httpClient.patch(url, { visibility: visibility })
  },

  // private offer
  async getSellerAccountsWithAccessToOffer(id) {
    const url = `${MANAGE_END_POINT}/${id}/access`
    return await httpClient.get(url)
  },

  async addSellerAccountAccessToOffer(id, accountId) {
    const url = `${MANAGE_END_POINT}/${id}/access/${accountId}`
    return await httpClient.post(url)
  },

  async removeSellerAccountAccessFromOffer(id, accountId) {
    const url = `${MANAGE_END_POINT}/${id}/access/${accountId}`
    return await httpClient.delete(url)
  }
}

export default SellerOfferApi
