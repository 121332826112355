import createHttpClient from './httpClient'

const END_POINT = 'manage'

const baseUrl = new URL('/api/v1/creatives', process.env.VUE_APP_PUBLIC_SERVICE_BASE_URL).href
const httpClient = createHttpClient(baseUrl)

const CreativeApi = { 
  async getScreeningPendingCreatives() {
    const url = `/${END_POINT}/pending`
    return await httpClient.get(url)
  },
  async getReviewedCreatives(showRejected) {
    const url = `/${END_POINT}?showRejected=${showRejected}`
    return await httpClient.get(url)
  },
  async getCreativeById(id) {
    const url = `/${END_POINT}/${id}`
    return await httpClient.get(url)
  },  
  async reviewCreative(id, payload) {
    const url = `/${END_POINT}/${id}/review`
    return await httpClient.post(url, payload)
  },
  async enableCreative(id) {
    const url = `/${END_POINT}/${id}/enable`
    return await httpClient.patch(url)
  },
  async disableCreative(id) {
    const url = `/${END_POINT}/${id}/disable`
    return await httpClient.patch(url)
  },
}

export default CreativeApi
