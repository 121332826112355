<template>
  <div>
    <CCard>
      <div
        v-if="isLoading"
        :style="{ height: '80vh' }"
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <CSpinner
          class="custom-spinner"
          color="info"
          grow
        />
      </div>
  
      <div v-else>
        <CCardHeader>
          <slot name="header">        
            <CIcon
              name="cil-basket"
              class="mr-1"
            /> Creatives
          </slot>
        </CCardHeader>
  
        <CCardBody>
          <div class="d-flex align-items-center mb-3">
            <CSwitch
              variant="3d"
              color="primary"
              horizontal
              size="sm"
              class="mr-2"           
              :checked.sync="showRejected"
              @update:checked="loadCreatives"
            />
            <h5 class="m-0">
              Show Rejected 
            </h5>          
          </div>
          <CDataTable
            small
            border
            sorter
            hover
            column-filter
            :items="formattedReviewedCreatives"
            :fields="fields"
            :items-per-page="10"
            :pagination="{align: 'center'}"
          >
            <template #id="{item}">
              <td>
                {{ item.id }}
                <a
                  href
                  class="ml-1"
                  @click.prevent="
                    copyIdToClipboard(getSafe(() => item.id))
                  "
                ><CIcon
                  name="cil-copy"
                /></a> 
              </td>
            </template>

            <template #accountName="{item}">
              <td>
                <router-link
                  :to="{ name: 'SellerAccountDetails', params: { id: getSafe(() => item.account.id)} }"
                  target="_blank"                 
                >
                  {{ getSafe(() => item.account.name) }}
                </router-link>

                <a
                  href
                  class="ml-1"
                  @click.prevent="
                    copyAccountIdToClipboard(getSafe(() => item.account.id))
                  "
                ><CIcon
                  name="cil-copy"
                /></a>            
              </td>
            </template>        

            <template #categoryName="{item}">
              <td>
                {{ getSafe(() => item.category.name) }}
              </td>
            </template>          

            <template #trafficSourceName="{item}">
              <td>
                {{ getSafe(() => item.trafficSource.name) }}
              </td>
            </template>

            <template #countryId="{item}">
              <td>
                {{ getSafe(() => item.country.id) }}
              </td>
            </template>

            <template #language="{item}">
              <td>
                {{ getSafe(() => item.language.id) }}
              </td>
            </template>

            <template #stats="{item}">
              <td>
                <div
                  v-if="item.stats"
                  class="w-fixed-130"
                >
                  <div>Total: {{ getSafe(() => item.stats.total) }}</div>
                  <div>Pending: {{ getSafe(() => item.stats.pending) }}</div>
                  <div>Approved: {{ getSafe(() => item.stats.approved) }}</div>
                  <div>Rejected: {{ getSafe(() => item.stats.rejected) }}</div>
                </div>
              </td>
            </template>

            <template #status="{item}">
              <td>
                <div>{{ getSafe(() => item.status) }}</div>
              </td>
            </template>

            <template #enabled="{item}">
              <td>
                <div><strong>{{ getSafe(() => item.enabled ? 'Yes' : 'No' ) }}</strong></div>
              </td>
            </template>

            <template #formattedCreationDate="{item}">
              <td>
                {{ item.formattedCreationDate }}
              </td>
            </template>

            <template #Actions="{item}">
              <td>
                <CButton
                  class="mt-1 ml-2"                
                  color="info"
                  size="sm"
                  :to="`/dashboard/creatives/${item.id}`"
                >
                  View
                </CButton>           
                <CButton
                  v-if="item.enabled"
                  class="mt-1 ml-2"
                  color="warning"
                  size="sm"
                  @click="showDisableModal(item)"
                >
                  Disable
                </CButton>
                <CButton
                  v-else 
                  class="mt-1 ml-2"
                  color="success"                
                  size="sm"
                  @click="showEnableModal(item)"
                >
                  Enable
                </CButton>
              </td>          
            </template>
          </CDataTable>
        </CCardBody>
      </div>
    </CCard>

    <CModal
      title="Confirm enabling creative"
      :show.sync="warningEnableModal"
    >
      Are you sure you want to enable this creative?

      <template #footer>
        <ButtonLoading
          color="success"
          :loading="isLoading"
          @click="enableCreativeFromModal"
        >
          Enable
        </ButtonLoading>

        <CButton
          color="danger"
          @click="warningEnableModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>

    <CModal
      title="Confirm disabling creative"
      :show.sync="warningDisableModal"
    >
      Are you sure you want to disable this creative?

      <template #footer>
        <ButtonLoading
          color="warning"
          :loading="isLoading"
          @click="disableCreativeFromModal"
        >
          Disable
        </ButtonLoading>

        <CButton
          color="danger"
          @click="warningDisableModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>    
  </div>
</template>
  
  <script>

  import { mapActions, mapState } from 'vuex'  
  import { showSuccessMessage, showErrorMessage } from '@/notification-utils'
  import { dateTimeFormat } from '@/utils'
  import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin' 
  
  export default {
    name: 'Creatives',
  
    title: 'Creatives',  

    mixins: [copyToClipboardMixin],
    
    data() {
      return {
        type: '',
        isLoading: false,       
        showRejected: false,
        warningEnableModal: false,
        warningDisableModal: false,
        selectedCreative: {}
      }
    },
  
    computed: {
      ...mapState('creative', ['reviewedCreatives']),
  
      fields () {
        return [
          { key: 'id', label: 'ID', sortable: true },
          { key: 'accountName', label: 'Account', sortable: true },
          { key: 'externalId', label: 'External ID', sortable: true },        
          { key: 'categoryName', label: 'Category', sortable: true },               
          { key: 'trafficSourceName', label: 'Traffic Source', sortable: true },               
          { key: 'countryId', label: 'Country', sortable: true },               
          { key: 'languageId', label: 'Language', sortable: true },               
          { key: 'status', label: 'Status', sortable: true },          
          { key: 'enabled', label: 'Enabled', sortable: true },          
          { key: 'stats', label: 'Buyer Approval Statistics', filter: false, sorter: false },               
          { key: 'formattedCreationDate', label: 'Created At', sorter: true },               
          { key: 'Actions', filter: false }
        ]
      },
   
      formattedReviewedCreatives() {
        return this.reviewedCreatives        
          .map(creative => {
            return {
              ...creative,
              formattedCreationDate: creative.creationDate ? dateTimeFormat.format(new Date(creative.creationDate)) : '',
              accountName: this.getSafe(() => creative.account.name),
              categoryName: this.getSafe(() => creative.category.name),
              trafficSourceName: this.getSafe(() => creative.trafficSource.name),
              countryId: this.getSafe(() => creative.country.id),
              languageId: this.getSafe(() => creative.language.id),
            }
        })
      },      
    },
  
    async created() {
      await this.loadCreatives()
    }, 

    methods: {
      ...mapActions('creative', ['getReviewedCreatives','enableCreative','disableCreative']),

      async loadCreatives() {
        try {
          this.isLoading = true
          await this.getReviewedCreatives(this.showRejected)
        } finally {
          this.isLoading = false
        }
      },

      copyIdToClipboard(id) {
        this.copyTextToClipboard(id)
        showSuccessMessage('Creative ID copied to clipboard')
      },

      copyAccountIdToClipboard(id) {
        this.copyTextToClipboard(id)
        showSuccessMessage('Account ID copied to clipboard')
      },

      showEnableModal (creative) {
        this.selectedCreative = creative
        this.warningEnableModal = true
      },

      showDisableModal (creative) {
        this.selectedCreative = creative
        this.warningDisableModal = true
      },

      async enableCreativeFromModal() {
        try {
          this.isLoading = true
          await this.enableCreative(this.selectedCreative.id)
          showSuccessMessage("Successfully enabled creative")
          this.selectedCreative.enabled = true
        } catch(error) {
          showErrorMessage("Failed to enable creative")
        } finally {
          this.warningEnableModal = false
          this.isLoading = false
        }
      },

      async disableCreativeFromModal() {
        try {
          this.isLoading = true
          await this.disableCreative(this.selectedCreative.id)
          
          showSuccessMessage("Successfully disabled creative")
          this.selectedCreative.enabled = false
        } catch(error) {
          showErrorMessage("Failed to disable creative")
        } finally {
          this.warningDisableModal = false
          this.isLoading = false
        }
      },
    }
  }
  </script>
  
  <style></style>
  